import './Games.css';
import './General.css';
import GameCard from '../components/GameCard'

const Games = (props) => {
    const listOfGames = props.games.map((game, index) => {
        return (
            <GameCard
                key = { index }
                game = { game }
            />
        );
    });

    return (
        <div id='games' className='container-fluid general-container games-container'>
            <div className='general-jumbotron'>
                <div className='games-header'>Games</div>
                {listOfGames}
            </div>
        </div>
    )
}

export default Games;
