import { Fragment } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap'

const GameCard = (props) => {
    const { title, imagePath, description, links } = { ...props.game }
    var linksToShow = <div/>
    if (links.length > 0) {
        linksToShow = links.map((link, index) => {
            var html = undefined;
            switch (link.type) {
                case "site":
                    html = (
                        <div key={index} className='general-links-row'>
                            <Button className='games-button' href={link.path} target='_blank' rel='noreferrer noopener'>
                                {link.text}
                            </Button>
                        </div>
                    );
                    break;
                case "google":
                    html = (
                        <div key={index} className='general-links-row'>
                            <a href={link.href} target='_blank' rel='noreferrer noopener'><Image className="google-play-btn" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        </div>
                    );
                    break;
                default:
                    html = (<div>Broken style!</div>);
                    break;
            }
            return html;
        });
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <Image className='general-image' src={imagePath} alt={title} fluid />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='games-text-row'>
                        <span>{description}</span>
                    </div>
                    {linksToShow}
                </Col>
            </Row>
            <br />
        </Fragment>
    );
}

export default GameCard;
