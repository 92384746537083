import './App.css';

import React, { Component, Fragment } from 'react';
import Blog from './containers/Blog';
import BlogNav from './containers/BlogNav';
import BlogPostWrapper from './components/BlogPostWrapper';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Fragment>
                <BlogNav />
                <Router>
                    <Switch>
                        <Route path="/:customPath" component={ BlogPostWrapper } />
                        <Route path="/echoes-from-the-void">
                            <Blog />
                        </Route>
                    </Switch>
                </Router>
            </Fragment>
        )
    }
}

export default App;
