import { Fragment } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap'

const NovelCard = (props) => {
    const { title, imagePath, description, linkButtons } = { ...props.novel }
    const linkButtonsToShow = linkButtons.map((linkButton, index) => {
        return (
            <Row key={index}>
                <Button className='novels-button' href={linkButton.link} target='_blank' rel='noreferrer noopener'>
                    {linkButton.text}
                </Button>
            </Row>
        )
    });

    return (
        <Fragment>
            <Row>
                <Col sm={3}>
                    <Image className='general-image' src={imagePath} alt={title} fluid />
                </Col>
                <Col>
                    <div className='novels-subtitle'><i>Into the Hive of Saarlathesh</i></div>
                    <Row>
                        <Col className="novels-desc">
                            {description}
                        </Col>
                    </Row>
                    <div className="d-grid gap-2">
                        {linkButtonsToShow}
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
}

export default NovelCard;
