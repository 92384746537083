import './General.css'
import './Novels.css'
import NovelCard from '../components/NovelCard'
import { Button, Col, Image, Row } from 'react-bootstrap'

const Novels = (props) => {
    const listOfNovels = props.novels.map((novel, index) => {
        return (
            <NovelCard
                key = { index }
                novel = { novel }
            />
        );
    });

    return (
        <div id='novels' className='container-fluid general-container novels-container'>
            <div className='general-jumbotron'>
                <div className='novels-header'>Novels</div>
                {listOfNovels}
                <Row className='goodreads-height-buffer'>
                    <Col>
                        <Button className='goodreads-button' href='https://www.goodreads.com/friend/add_as_friend/28193833' target='_blank' rel='noreferrer noopener'>
                            <Image src='https://www.goodreads.com/images/icons/goodreads_icon_50x50.png?1297385984'></Image>
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Novels;
