import { Component } from 'react';

class Healthcheck extends Component {
    addAZero(val) {
        if (val < 10) {
            val = '0' + val;
        }
        return val;
    }

    render() {
        let date = new Date();
        let month = this.addAZero(date.getMonth() + 1);
        let day = this.addAZero(date.getDay());
        let hours = this.addAZero(date.getHours());
        let minutes = this.addAZero(date.getMinutes());
        let seconds = this.addAZero(date.getSeconds());
        let milliseconds = date.getMilliseconds();
        let now = `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
        let healthCheck = {
            'date': now,
            'healthy': true
        };
        return (
            <div>{JSON.stringify(healthCheck)}</div>
        )
    }
}

export default Healthcheck;
