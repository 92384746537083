import './BlogNav.css'
import { Button, Navbar } from 'react-bootstrap'

const BlogNav = () => {

    return (
        <Navbar bg='dark' variant='dark' sticky='top' className='container-fluid nav-container'>
            <Button className='blog-nav blog-nav-margin' size='md' href='/home'>Leave this place</Button>
        </Navbar>
    );
}

export default BlogNav;
