import { Component } from 'react';
import Blog from '../containers/Blog';
import BlogPost from './BlogPost'


class BlogPostWrapper extends Component {
    constructor(props) {
        super(props)
        this.state={
          blogPost: null
        }
    }

    importAllPosts(requireContext) {
        var refreshedPosts = {};
        const yearRegex = /\/(.*)\//;
        requireContext.keys().forEach(key => {
            let matches = yearRegex.exec(key);
            if (matches) {
                if (!(matches[1] in refreshedPosts)) {
                    refreshedPosts[matches[1]] = [];
                }
                var jsonObj = requireContext(key);
                refreshedPosts[matches[1]].push(jsonObj);
            }
        });
        return refreshedPosts;
    }

    componentDidMount() {
        let pathname = this.props.location.pathname.substr(1).split('/')[1]
        if (pathname === undefined || pathname === "echoes-from-the-void") {
            return;
        }
        // Load all blog posts and images from relative folders
        var refreshedPosts = this.importAllPosts(require.context("../documents/blog/", true, /.json$/));
        //
        Object.keys(refreshedPosts).forEach(year => {
            // Sort posts in descending date order
            refreshedPosts[year].sort(post => post.date).reverse();
            refreshedPosts[year].forEach(post => {
                if (post.date === pathname) {
                    this.setState({
                        blogPost: post
                    });
                    window.history.replaceState(null, post.title, pathname)
                }
            })
        });
    }

    render() {
        if (this.state.blogPost !== null) {
            return <BlogPost
                post = { this.state.blogPost }
                closePost = { null }
            />
        }
        return <Blog />
    }
}

export default BlogPostWrapper;
