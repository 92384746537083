import { Fragment, useRef, useState } from 'react';
import { Button, Col, Overlay, Row, Tooltip } from 'react-bootstrap'
import { GiShare } from 'react-icons/gi';

const BlogPreview = (props) => {
    const { title, date, preview } = { ...props.post };
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    const getShareButton = () => {
        return <Fragment>
            <Button ref={target} className='button' size='md' onClick={() => {setShowTooltip(!showTooltip); navigator.clipboard.writeText(`https://jonathanferreira.com/echoes-from-the-void/${date}`)}}>
                <GiShare/>
            </Button>
            <Overlay target={target.current} show={showTooltip} placement='right-end' rootClose={true} rootCloseEvent='click' onHide={() => setShowTooltip(false)}>
                <Tooltip>Link copied!</Tooltip>
            </Overlay>
        </Fragment>
    };

    return (
        <Fragment>
            <div className='container-fluid general-container post post-container'>
                <div className='jumbotron'>
                    <Row>
                        <Col>
                            <div className='title'>{title} {getShareButton()}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='date'>{date}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text">{preview}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className='button' size='md' onClick={() => props.handlePostClick(props.post)}>Hear this echo</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

export default BlogPreview;
