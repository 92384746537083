import { Fragment, useRef, useState } from 'react';
import { Button, Col, Image, Overlay, Row, Tooltip } from 'react-bootstrap'
import { GiShare } from 'react-icons/gi';

const BlogPost = (props) => {
    const { title, date, body } = { ...props.post };
    const contents = body.map((element, index) => {
        var html = undefined;
        switch (element.type) {
            case "p":
                if (element.header) {
                    html = <div><h2 className='text'>{element.header}</h2><p key={index} className="text" dangerouslySetInnerHTML={{__html: element.value}}></p></div>;
                }
                else if (element.subheader) {
                    html = <div><h4 className='text'>{element.subheader}</h4><p key={index} className="text" dangerouslySetInnerHTML={{__html: element.value}}></p></div>;
                }
                else {
                    html = <p key={index} className="text" dangerouslySetInnerHTML={{__html: element.value}}></p>;
                }
                break;
            case "p-continuous":
                html = [];
                if (element.header) {
                    html.push(<h2 className='text'>{element.header}</h2>);
                }
                else if (element.subheader) {
                    html.push(<h4 className='text'>{element.subheader}</h4>);
                }
                element.values.forEach((p, inner) => {
                    html.push(<p key={index + "_" + inner} className="text" dangerouslySetInnerHTML={{__html: p}}></p>);
                });
                break;
            case "img":
                html = [];
                html.push(<Image key={index} className="general-image image" src={element.src} alt={element.alt} fluid />);
                if (element.caption) {
                    html.push(<p key={index} className="caption">{element.caption}</p>);
                }
                break;
            case "ul":
                var items = [];
                element.values.forEach((p, inner) => {
                    items.push(<li key={index + "_" + inner} className="text" dangerouslySetInnerHTML={{__html: p}}></li>);
                });
                html = <div><h2 className='text'>{element.header}</h2><ul>{items}</ul></div>;
                break;
            default:
                html = <p>Broken element!</p>
                break;
        }
        return html;
    });

    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    const getShareButton = () => {
        return <Fragment>
            <Button ref={target} className='button' size='md' onClick={() => {setShowTooltip(!showTooltip); navigator.clipboard.writeText(`https://jonathanferreira.com/echoes-from-the-void/${date}`)}}>
                <GiShare/>
            </Button>
            <Overlay target={target.current} show={showTooltip} placement='right-end' rootClose={true} rootCloseEvent='click' onHide={() => setShowTooltip(false)}>
                <Tooltip>Link copied!</Tooltip>
            </Overlay>
        </Fragment>
    };

    return (
        <Fragment>
            <div className='container-fluid general-container post post-container'>
                <div className='jumbotron'>
                    <Row>
                        <Col>
                            {
                                props.closePost == null ?
                                    (
                                        <Button className='return' size='md' href='/echoes-from-the-void' >Return to the void</Button>
                                    ) :
                                    (
                                        <Button className='return' size='md' onClick={() => props.closePost()} >Return to the void</Button>
                                    )
                            }
                        </Col>
                    </Row>
                    <hr className="divider rounded"/>
                    <Row>
                        <Col>
                            <div className='title'>{title} {getShareButton()}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='date'>{date}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {contents}
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

export default BlogPost;
