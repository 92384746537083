import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Echoes from './Echoes';
import Healthcheck from './Healthcheck';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

// Importing Bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Switch>
            <Route path="/health">
                <Healthcheck />
            </Route>
            <Route path="/echoes-from-the-void">
                <Echoes />
            </Route>
            <Route path="/">
                <App />
            </Route>
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
