import './Contact.css'
import './General.css'
import { Button, Col, Row } from 'react-bootstrap'
import { FaEnvelopeSquare, FaFilm, FaGithub, FaLinkedin } from 'react-icons/fa'

const Contact = () => {
    return (
        <div id='contact' className='container-fluid general-container contact-container'>
            <div className='general-jumbotron'>
                <div className='general-header'>Contact Me</div>
                <Row>
                    <Col className='email-text'>
                        <FaEnvelopeSquare /> <a href="mailto:jonferreira@protonmail.ch">jonferreira@protonmail.ch</a>
                    </Col>
                </Row>
                <Row className='center-div general-links-row'>
                    <Col>
                        <Button className='btn-github' target='_blank' rel='noreferrer noopener' href='https://github.com/johnnytoxin'>
                            <FaGithub /> GitHub
                        </Button>
                        <Button className='btn' target='_blank' rel='noreferrer noopener' href='https://www.linkedin.com/in/johnnytoxin'>
                            <FaLinkedin /> LinkedIn
                        </Button>
                        <Button className='btn-letterboxd' target='_blank' rel='noreferrer noopener' href='https://letterboxd.com/thejohnnytoxin/'>
                            <FaFilm /> Letterboxd
                        </Button>
                        <Button className='btn-backloggd' target='_blank' rel='noreferrer noopener' href='https://backloggd.com/u/thejohnnytoxin/'>
                            <img className='img-backloggd' src='https://backloggd.com/favicon.ico' alt="Button link to Johnny's profile on Backloggd.com"></img> Backloggd
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Contact;
