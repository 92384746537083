import './Blog.css'
import './General.css'
import { Col, Row } from 'react-bootstrap'
import React, { Component, Fragment } from 'react'
import BlogPost from '../components/BlogPost'
import BlogPreview from '../components/BlogPreview'

class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buttonYears: [],
            images: [],
            posts: [],
            postsByYear: {}
        }
    }

    closePost = () => {
        this.setState({
            selectedPost: undefined
        });
    }

    handlePostClick = (post) => {
        window.scrollTo(0, 0);
        this.setState({
            selectedPost: (
                <BlogPost
                    post = { post }
                    closePost = { this.closePost }
                />
            )
        });
    }

    importAllImages(requireContext) {
        var images = {};
        const filenameRegex = /\/.+\/(.+.(?:png|jpg|gif))$/;
        requireContext.keys().forEach(key => {
            let matches = filenameRegex.exec(key);
            if (matches) {
                if (!(matches[1] in images)) {
                    images[matches[1]] = '/images/blog' + matches[0];
                }
            }
        })
        return images;
    }

    importAllPosts(requireContext) {
        var refreshedPosts = {};
        const yearRegex = /\/(.*)\//;
        requireContext.keys().forEach(key => {
            let matches = yearRegex.exec(key);
            if (matches) {
                if (!(matches[1] in refreshedPosts)) {
                    refreshedPosts[matches[1]] = [];
                }
                var jsonObj = requireContext(key);
                refreshedPosts[matches[1]].push(jsonObj);
            }
        });
        return refreshedPosts;
    }

    loadBlogPosts(posts) {
        if (posts !== undefined)
        {
            var blogPosts = posts.map((post, index) => {
                return (
                    <BlogPreview
                        key = { index }
                        post = { post }
                        handlePostClick = { this.handlePostClick }
                    />
                );
            });
            this.setState({
                posts: blogPosts
            });
        }
    }

    componentDidMount() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        // Load all blog posts and images from relative folders
        var refreshedPosts = this.importAllPosts(require.context("../documents/blog/", true, /.json$/));
        var images = this.importAllImages(require.context("../../public/images/blog/", true, /.png|.jpg|.gif$/));
        console.log(images);
        //
        Object.keys(refreshedPosts).forEach(year => {
            // Sort posts in descending date order
            refreshedPosts[year].sort(post => post.date).reverse();
            // Rewrap img src files with context loaded paths
            refreshedPosts[year].forEach(post => {
                post.body.forEach(element => {
                    if (element.type === "img") {
                        element.src = images[element.src];
                    }
                });
            });
        });
        // Create buttons for each year
        var buttonYears = [];
        Object.keys(refreshedPosts).sort().reverse().forEach((year, index) => {
            buttonYears.push(
                <button key={index} className="button" onClick={() => this.loadBlogPosts(this.state.postsByYear[year])}>{year}</button>
            );
        });
        this.setState({
            buttonYears: buttonYears,
            postsByYear: refreshedPosts
        });
        while (refreshedPosts[year] === undefined) {
            year--;
        }
        this.loadBlogPosts(refreshedPosts[year]);
    }
    
    render() {
        return (
            <Fragment>
                {
                    this.state.selectedPost ?
                        this.state.selectedPost :
                        (
                            <div>
                                <div id='echoes' className='container-fluid general-container echoes-container'>
                                    <div className='jumbotron'>
                                        <Row>
                                            <Col>
                                                <div className='general-header'>Echoes from the Void</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className='intro'>We always seem to be screaming into the void, but if you take the time to listen, you may find the void screams back.</div>
                                            </Col>
                                        </Row>
                                        <hr className="divider rounded"/>
                                        <div className="blog-archive">
                                            <p className="title">Archive</p>
                                            {this.state.buttonYears}
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.posts.length > 0
                                        ? this.state.posts
                                        : <div/>
                                }
                            </div>
                        )
                }
            </Fragment>
        );
    }
}

export default Blog;
