import './NavBar.css';
import { FaAddressCard, FaBlog, FaBook, FaGamepad, FaMale } from 'react-icons/fa';
import { Button, Navbar } from 'react-bootstrap'

const NavBar = () => {
    return (
        <Navbar sticky='top' className='container-fluid nav-container'>
            <Button className='nav-button' size='md' href='#home'><FaMale size={24} /></Button>
            <Button className='nav-button' size='md' href='#games'><FaGamepad size={24} /></Button>
            <Button className='nav-button' size='md' href='#novels'><FaBook size={24} /></Button>
            <Button className='nav-button' size='md' href='#contact'><FaAddressCard size={24} /></Button>
            <Button className='nav-button' size='md' href='/echoes-from-the-void'><FaBlog size={24} /></Button>
        </Navbar>
    );
}

export default NavBar;
